import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendToDataApi } from './../api/sendToDataApi';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Grid,
    Container,
    Paper,
    Typography,
    Divider,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { allManagerListSelector } from '../data/selectors/managerListSelector';
import { loadManagerListAction } from '../data/actions/managerListActions';
import { addUserLogDateApi } from '../api/logDataApi';
import { setSnackbarAction } from '../data/actions/snackbarActions';
import { getProductListApi } from '../api/productsApi';

const organizationList = [
    {
        id: 1,
        managerId: 1,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 2,
        managerId: 44,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 3,
        managerId: 43,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 4,
        managerId: 42,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 5,
        managerId: 3,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 6,
        managerId: 51,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 7,
        managerId: 2,
        organizationName: 'ФОП КУДИНА ВОЛОДИМИР ОЛЕКСАНДРОВИЧ'
    },
    {
        id: 8,
        managerId: 5,
        organizationName: 'ФОП Ковальчук Людмила Іванівна'
    },
    {
        id: 9,
        managerId: 50,
        organizationName: 'ГНАТЮК АНТОН ІВАНОВИЧ'
    },
    {
        id: 10,
        managerId: 49,
        organizationName: 'ГНАТЮК АНТОН ІВАНОВИЧ'
    },
    {
        id: 11,
        managerId: 8,
        organizationName: 'ЧУМАК ВІКТОРІЯ ВАДИМІВНА'
    },
    {
        id: 12,
        managerId: 7,
        organizationName: 'АФАНАСЬЄВ ВЯЧЕСЛАВ СТАНІСЛАВОВИЧ'
    },
    {
        id: 13,
        managerId: 7,
        organizationName: 'ГНАТЮК АНТОН ІВАНОВИЧ'
    },
    {
        id: 14,
        managerId: 7,
        organizationName: 'ІГНАТЬЄВ ЕДУАРД СЕРГІЙОВИЧ'
    },
    {
        id: 15,
        managerId: 7,
        organizationName: 'КОВАЛЬЧУК ЛЮДМИЛА ІВАНІВНА'
    },
    {
        id: 16,
        managerId: 7,
        organizationName: 'КОВАЛЬЧУК ОЛЕКСАНДР ВАСИЛЬОВИЧ'
    },
    {
        id: 17,
        managerId: 7,
        organizationName: 'КОВАЛЬЧУК ОЛЕНА ОЛЕКСІЇВНА'
    },
    {
        id: 18,
        managerId: 7,
        organizationName: 'НЕЧИПОРЕНКО РОМАН АНДРІЙОВИЧ'
    },
    {
        id: 19,
        managerId: 7,
        organizationName: 'ЧУМАК ВІКТОРІЯ ВАДИМІВНА'
    },
    {
        id: 20,
        managerId: 7,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 21,
        managerId: 15,
        organizationName: 'АФАНАСЬЄВ ВЯЧЕСЛАВ СТАНІСЛАВОВИЧ'
    },
    {
        id: 22,
        managerId: 15,
        organizationName: 'ГНАТЮК АНТОН ІВАНОВИЧ'
    },
    {
        id: 23,
        managerId: 15,
        organizationName: 'ІГНАТЬЄВ ЕДУАРД СЕРГІЙОВИЧ'
    },
    {
        id: 24,
        managerId: 15,
        organizationName: 'КОВАЛЬЧУК ЛЮДМИЛА ІВАНІВНА'
    },
    {
        id: 25,
        managerId: 15,
        organizationName: 'КОВАЛЬЧУК ОЛЕКСАНДР ВАСИЛЬОВИЧ'
    },
    {
        id: 26,
        managerId: 15,
        organizationName: 'КОВАЛЬЧУК ОЛЕНА ОЛЕКСІЇВНА'
    },
    {
        id: 27,
        managerId: 15,
        organizationName: 'НЕЧИПОРЕНКО РОМАН АНДРІЙОВИЧ'
    },
    {
        id: 28,
        managerId: 15,
        organizationName: 'ЧУМАК ВІКТОРІЯ ВАДИМІВНА'
    },
    {
        id: 29,
        managerId: 15,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 30,
        managerId: 15,
        organizationName: 'ТОВ ЕЛЕГАНТ ГРУП УКРАЇНА'
    },
    {
        id: 31,
        managerId: 53,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 32,
        managerId: 54,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 33,
        managerId: 55,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 34,
        managerId: 52,
        organizationName: 'ФОП КУДИНА ВОЛОДИМИР ОЛЕКСАНДРОВИЧ'
    },
    {
        id: 35,
        managerId: 57,
        organizationName: 'ЯКОВЕНКО ОЛЬГА МИКОЛАЇВНА'
    },
    {
        id: 36,
        managerId: 59,
        organizationName: 'ГНАТЮК АНТОН ІВАНОВИЧ'
    },
    {
        id: 37,
        managerId: 58,
        organizationName: 'ФОП КУДИНА ВОЛОДИМИР ОЛЕКСАНДРОВИЧ'
    },
]

const CalcFormContainer = () => {

    const dispatch = useDispatch();

    // function jsonToSqlInsert(products, tableName) {
    //     // Функция для преобразования одного объекта в SQL-запрос
    //     const convertToSql = (data) => {
    //       const keys = Object.keys(data);
    //       const values = Object.values(data).map(value =>
    //         typeof value === 'string' ? `'${value.replace(/'/g, "''")}'` : value
    //       );
    //       return `INSERT INTO ${tableName} (${keys.join(', ')}) VALUES (${values.join(', ')});`;
    //     };

    //     // Преобразование каждого объекта в SQL и сбор результатов в массив
    //     const sqlQueries = products.map(product => convertToSql(product));

    //     return sqlQueries;
    //   }

    //   const tableName = 'opt_products';
    //   const sqlInsertQueries = jsonToSqlInsert(products, tableName);

    //   console.log(sqlInsertQueries.join('\n'));

    const [products, setProducts] = useState([])
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [contractorName, setContractorName] = useState('');
    const [phone, setPhone] = useState('');
    const [managerError, setManagerError] = useState(false);
    const [organizationError, setOrganizationError] = useState(false);
    const [contractorNameError, setContractorNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [recipient, setRecipient] = useState('');
    const [recipientPhone, setRecipientPhone] = useState('');
    const [location, setLocation] = useState('uk-UA');
    const [city, setCity] = useState('');
    const [branchNumber, setBranchNumber] = useState('');
    const [recipientError, setRecipientError] = useState(false);
    const [recipientPhoneError, setRecipientPhoneError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [branchNumberError, setBranchNumberError] = useState(false);
    const [productRows, setProductRows] = useState([
        { product: '', quantity: 0, total: 0, productError: false, quantityError: false },
    ]);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [invoiceChanges, setInvoiceChanges] = useState('');
    const [isSending, setIsSending] = useState(false);
    // const [templates, setTemplates] = useState([]);
    // console.log("🚀 ~ file: CalcFormContainer.js:234 ~ CalcFormContainer ~ templates:", templates)

    const userData = useSelector(userDataSelector)
    const managerList = useSelector(allManagerListSelector);

    const singleManager = {
        id: userData.managerId,
        managerName: userData.managerName
    }
    const managerRole = userData.role
    const excludedManagerNames = ['Всі менеджери'];
    const filteredManagers = managerList.filter(manager => !excludedManagerNames.includes(manager.managerName));

    const [selectedManager, setSelectedManager] = useState(managerRole == "Manager1" ? singleManager : '');

    const managerOrganizationList = organizationList.filter((item) => item.managerId === selectedManager.id);

    // const handleSaveTemplate = () => {
    //     const template = {
    //         manager: selectedManager,
    //         organization: selectedOrganization,
    //         contractorName,
    //         phone,
    //         recipient,
    //         recipientPhone,
    //         city,
    //         branchNumber,
    //     };
    //     setTemplates([...templates, template]);
    // };

    // const handleLoadTemplate = (template) => {
    //     setSelectedManager(template.manager);
    //     setSelectedOrganization(template.organization);
    //     setContractorName(template.contractorName);
    //     setPhone(template.phone);
    //     setRecipient(template.recipient);
    //     setRecipientPhone(template.recipientPhone);
    //     setCity(template.city);
    //     setBranchNumber(template.branchNumber);
    // };

    const handleManagerChange = (value) => {
        setSelectedManager(value);
        setManagerError(false);
    };

    const handleOrganizationChange = (value) => {
        setSelectedOrganization(value);
        setOrganizationError(false);
    };

    const handleContractorNameChange = (value) => {
        setContractorName(value);
        setContractorNameError(false);
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
        setPhoneError(false);
    };

    const handleRecipientChange = (value) => {
        setRecipient(value);
        setRecipientError(false);
    };

    const handleRecipientPhoneChange = (value) => {
        setRecipientPhone(value);
        setRecipientPhoneError(false);
    };

    const handleCityChange = (value) => {
        setCity(value);
        setCityError(false);
    };

    const handleBranchNumberChange = (value) => {
        setBranchNumber(value);
        setBranchNumberError(false);
    };

    const handleAddProductRow = () => {
        setProductRows([...productRows, { product: '', quantity: 0, total: 0 }]);
    };

    const handleProductChange = (index, value) => {
        const updatedRows = [...productRows];
        updatedRows[index].product = value;
        updatedRows[index].productError = false;

        const selectedProduct = products.find(product => product.sku === value.sku);
        updatedRows[index].price = selectedProduct ? selectedProduct.price : 0;

        updatedRows[index].total =
            value && updatedRows[index].quantity !== ''
                ? parseFloat(updatedRows[index].quantity) * updatedRows[index].price
                : 0;

        setProductRows(updatedRows);
    };

    const handleDeleteProduct = (index) => {
        const updatedProducts = [...productRows];
        updatedProducts.splice(index, 1);
        setProductRows(updatedProducts);

        const newTotalWithoutDiscount = updatedProducts.reduce(
            (accumulator, row) => accumulator + row.total,
            0
        );

        const newDiscountAmount = newTotalWithoutDiscount >= 5000
            ? (newTotalWithoutDiscount * discountPercentage) / 100
            : 0;

        setDiscountAmount(Math.trunc(newDiscountAmount));
    };

    const handleQuantityChange = (index, value) => {
        const updatedRows = [...productRows];
        updatedRows[index].quantity = value;
        updatedRows[index].quantityError = false;

        const product = products.find(product => product.sku === updatedRows[index].product.sku);
        updatedRows[index].total =
            product && value !== '' && parseFloat(value) !== 0
                ? parseFloat(value) * product.price
                : 0;

        setProductRows(updatedRows);

        const newTotalWithoutDiscount = updatedRows.reduce(
            (accumulator, row) => accumulator + row.total,
            0
        );

        const newDiscountAmount = newTotalWithoutDiscount >= 5000
            ? (newTotalWithoutDiscount * discountPercentage) / 100
            : 0;

        setDiscountAmount(Math.trunc(newDiscountAmount));
    };

    const handleDiscountChange = (value) => {
        const discountPercentage = value > 0 ? parseFloat(value) : 0;
        setDiscountPercentage(discountPercentage);

        const calculatedDiscountAmount = totalWithoutDiscount >= 5000
            ? (totalWithoutDiscount * discountPercentage) / 100
            : 0;

        setDiscountAmount(Math.trunc(calculatedDiscountAmount));
    };

    const totalWithoutDiscount = productRows.reduce(
        (accumulator, row) => accumulator + row.total,
        0
    );

    const totalWithDiscount = totalWithoutDiscount - discountAmount;



    const handleAdditionalInfoChange = (value) => {
        setAdditionalInfo(value);
    };

    const handleInvoiceChangesChange = (value) => {
        setInvoiceChanges(value);
    };

    document.addEventListener("keydown", function (e) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        document.addEventListener("keydown", function (e) {
            if (e.key === "Enter") {
                e.preventDefault();
            }
        });

        const fieldValidations = [
            { field: selectedManager, errorState: setManagerError },
            { field: selectedOrganization, errorState: setOrganizationError },
            { field: contractorName.trim(), errorState: setContractorNameError },
            { field: phone.trim(), errorState: setPhoneError },
            { field: recipient.trim(), errorState: setRecipientError },
            { field: recipientPhone.trim(), errorState: setRecipientPhoneError },
            { field: city.trim(), errorState: setCityError },
            { field: branchNumber.trim(), errorState: setBranchNumberError },
        ];

        fieldValidations.forEach(validation => {
            if (!validation.field) {
                validation.errorState(true);
                dispatch(setSnackbarAction(true, "error", "Помилка. Є не заповнені поля."))
            } else {
                validation.errorState(false);
            }
        });

        const updatedRows = productRows.map((row) => {
            if (!row.product) row.productError = true;
            if (row.quantity === 0) row.quantityError = true;
            return row;
        });

        setProductRows(updatedRows);

        if (updatedRows.some((row) => row.productError || row.quantityError) || fieldValidations.some(field => !field.field) || totalWithDiscount == 0) {
            // console.log("🚀 ~ file: CalcFormContainer.js:261 ~ handleSubmit ~ (updatedRows.some:", updatedRows.some((row) => row.productError || row.quantityError))
            // console.log("🚀 ~ file: CalcFormContainer.js:262 ~ handleSubmit ~ (fieldValidations.some:", fieldValidations.some(field => !field.field))
            // console.log("🚀 ~ file: CalcFormContainer.js:263 ~ handleSubmit ~ (fieldValidations:", fieldValidations)
            return;
        }

        const formData = {
            manager: selectedManager,
            organization: selectedOrganization.organizationName,
            contractorName,
            phone,
            recipient,
            recipientPhone,
            city,
            branchNumber,
            products: productRows,
            discountAmount,
            totalWithDiscount,
            additionalInfo,
            invoiceChanges,
        };
        sendTo(formData);
    };

    const resetForm = () => {
        setSelectedManager(managerRole == "Manager1" ? singleManager : '');
        setSelectedOrganization('');
        setContractorName('');
        setPhone('');
        setRecipient('');
        setRecipientPhone('');
        setCity('');
        setBranchNumber('');
        setProductRows([
            { product: '', quantity: 0, total: 0, productError: false, quantityError: false },
        ]);
        setDiscountPercentage(0);
        setDiscountAmount(0);
        setAdditionalInfo('');
        setInvoiceChanges('');
    };

    const sendTo = async (formData) => {
        setIsSending(true);
        try {
            const res = await sendToDataApi(userData, formData);

            if (res.data && res.data.ok) {
                dispatch(setSnackbarAction(true, "success", "Дані відправлено."));
                resetForm();
                await addUserLogDateApi(userData, `/orderForm/added/${res.data.result.message_id}`);
                console.log(formData);
            } else {
                console.error("Error response:", res.error || res.data);
                const errorMessage = res.error === 'Network error. Please check your internet connection.'
                    ? 'Перевірте підключення інтернету'
                    : res.error || "Помилка. Дані не відправлено.";
                await addUserLogDateApi(userData, `/orderForm-error/${res.error || res.data.error || 'unknown'}/${res.data?.result?.message_id || 'unknown'}`);
                dispatch(setSnackbarAction(true, "error", errorMessage));
            }
        } catch (error) {
            console.error("Exception caught:", error);
            dispatch(setSnackbarAction(true, "error", "Помилка. Дані не відправлено.."));
        } finally {
            setIsSending(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                managerRole !== "Manager1" && dispatch(loadManagerListAction(userData));
                const productList = await getProductListApi(userData);
                setProducts(productList);
                await addUserLogDateApi(userData, '/orderForm');
            } catch (error) {
                console.error("Ошибка при выполнении API запросов:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <Container maxWidth="md" style={{ marginTop: "100px", textAlign: "center" }}>
                <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
                    <Typography variant="h6" gutterBottom>
                        Контрагент:
                    </Typography>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={handleSaveTemplate}>
                                Зберегти як шаблон
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel>Вибрати шаблон</InputLabel>
                            <Select
                                label="Вибрати шаблон"
                                value={null}
                                onChange={(e) => handleLoadTemplate(e.target.value)}
                            >
                                <MenuItem value={null}>Без шаблона</MenuItem>
                                {templates.map((template, index) => (
                                    <MenuItem key={index} value={template}>
                                        Шаблон {index + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth error={managerError}>
                                <InputLabel>Менеджер</InputLabel>
                                <Select
                                    label="Менеджер"
                                    value={selectedManager}
                                    onChange={(e) => handleManagerChange(e.target.value)}
                                >
                                    {managerRole === "Manager1" && <MenuItem key={selectedManager.id} value={selectedManager}>
                                        {selectedManager.managerName}
                                    </MenuItem>}
                                    {filteredManagers.map((manager, index) => (
                                        <MenuItem key={index} value={manager}>
                                            {manager.managerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth error={organizationError}>
                                <InputLabel>Організація</InputLabel>
                                <Select
                                    label="Організація"
                                    value={selectedOrganization}
                                    onChange={(e) => handleOrganizationChange(e.target.value)}
                                >
                                    {managerOrganizationList &&
                                        managerOrganizationList.map((organization) => (
                                            <MenuItem key={organization.id} value={organization}>
                                                {organization.organizationName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Контрагент ПІБ"
                                value={contractorName}
                                onChange={(e) => handleContractorNameChange(e.target.value)}
                                error={contractorNameError}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Номер телефону"
                                value={phone}
                                onChange={(e) => handlePhoneChange(e.target.value)}
                                error={phoneError}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
                    <Typography variant="h6" gutterBottom>
                        Отримувач:
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Отримувач"
                                value={recipient}
                                onChange={(e) => handleRecipientChange(e.target.value)}
                                error={recipientError}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Номер телефону отримувача"
                                value={recipientPhone}
                                onChange={(e) => handleRecipientPhoneChange(e.target.value)}
                                error={recipientPhoneError}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Місто"
                                value={city}
                                onChange={(e) => handleCityChange(e.target.value)}
                                error={cityError}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Номер відділення"
                                value={branchNumber}
                                onChange={(e) => handleBranchNumberChange(e.target.value)}
                                error={branchNumberError}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
                    <Typography variant="h6" gutterBottom>
                        Замовлення:
                    </Typography>
                    <Grid container spacing={2}>
                        {productRows.map((row, index) => (
                            <React.Fragment key={index}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} sm={4}>
                                        <FormControl variant="outlined" fullWidth error={row.productError}>
                                            <InputLabel>Товар</InputLabel>
                                            <Select
                                                label="Товар"
                                                value={row.product}
                                                onChange={(e) => handleProductChange(index, e.target.value)}
                                            >
                                                {products.map((product) => (
                                                    <MenuItem key={product.sku} value={product} style={!product.available ? { color: 'red' } : null}>
                                                        {product.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Ціна"
                                            disabled
                                            value={row.price > 0 ? row.price : 0}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Кількість"
                                            type="number"
                                            value={row.quantity > 0 ? row.quantity : ''}
                                            onChange={(e) => handleQuantityChange(index, e.target.value)}
                                            error={row.quantityError}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Сума"
                                            disabled
                                            value={row.total}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <Button variant="contained" color="primary" onClick={() => handleDeleteProduct(index)}>
                                            <DeleteIcon />
                                        </Button>
                                    </Grid>
                                    <Divider style={{ width: "100%", margin: "10px 0 30px" }} />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={12} style={{ margin: "20px", marginBottom: "40px" }}>
                        <Button variant="contained" color="primary" onClick={handleAddProductRow}>
                            Додати товар
                        </Button>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Ввести знижку %"
                                value={discountPercentage > 0 ? discountPercentage : ''}
                                onChange={(e) => handleDiscountChange(e.target.value)}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Сума без знижки"
                                disabled
                                value={totalWithoutDiscount}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Знижка"
                                disabled
                                value={discountAmount}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Сума"
                                disabled
                                value={totalWithDiscount}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Додаткова інформація"
                                value={additionalInfo}
                                helperText="Покласти семпл, каталог, тощо"
                                onChange={(e) => handleAdditionalInfoChange(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Зміна даних накладної"
                                value={invoiceChanges}
                                helperText="Контрагент, число"
                                onChange={(e) => handleInvoiceChangesChange(e.target.value)}
                            />
                        </Grid>
                        {/* <Grid item xs={6} style={{ marginTop: "20px" }}>
                            <Button type="submit" variant="contained" color="primary" onClick={(e) => resetForm(e)}>
                                Очистити
                            </Button>
                        </Grid> */}
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Button type="submit" variant="contained" color="secondary" disabled={isSending}>
                                Відправити
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </form >
    );
};

export default CalcFormContainer;
