import React, { useEffect, useState, useRef } from 'react';
import { TableRow, TableCell, TextField, Paper, Grid, Box, TableContainer, TableBody, Table, TableHead, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updatePromocodeItemAction } from '../../data/actions/bonusSystemActions';
import UniversalInput from './UniversalInput';
import UniversalSelect from './UniversalSelect';
import { doctorCategory, regionNameList, specialistType } from '../../data/constants/selects';
import { Typography } from '@material-ui/core';
import Comment from './Comment';
import { monthNameList } from '../../data/constants/dates';
import { getUpdateDataItemApi } from '../../api/updateDataApi';
import { setSnackbarAction } from '../../data/actions/snackbarActions';

const ItemCard = ({ userData, selectedItem, saleQtySum, salePriceSum, productsByQuantity, selectedBonusItem, previousMonths, selectedData, promocodeComments, setDoctorFilter, setIsSaved, isSaved }) => {

  const [inputData, setInputData] = useState(selectedItem);
  const [disabled, setDisabled] = useState(selectedItem.activated);
  const dispatch = useDispatch();

  const toggleDisabled = () => {
    const newStatus = !disabled;
    setDisabled(newStatus);

    const updatedData = {
      activated: newStatus,
    };

    dispatch(updatePromocodeItemAction(selectedItem.id, updatedData, userData));
  };

  const handleInputChange = (itemId, field, event) => {
    if (!itemId || !field || !event || !event.target) return;
    setInputData({
      ...inputData,
      [field]: event.target.value,
    });
    setIsSaved(false);
  };

  const handleSelectChange = (itemId, field, event) => {
    if (!itemId || !field || !event || !event.target) return;
    const updatedData = {
      ...inputData,
      [field]: event.target.value,
    };
    setInputData(updatedData);
    setIsSaved(false);

    const { id, ...data } = updatedData;
    if (!id || !data) return;
  };

  const handleSave = () => {
    const { id, ...data } = inputData;
    if (!id || !data) return dispatch(setSnackbarAction(true, "error", "Помилка! Дані не збережені."));
    if (data.region === '') return dispatch(setSnackbarAction(true, "error", "Помилка! Заповніть обов'язкове поле Область."));
    dispatch(updatePromocodeItemAction(id, data, userData)).then(() => {
      const typeValue = 'calcPromocodeBonusItem';
      getUpdateDataItemApi(userData, typeValue, id);
      setDoctorFilter(data.doctor);
      setIsSaved(true);
    });
  };

  useEffect(() => {
    const updatedData = {
      id: selectedItem.id || null,
      city: selectedItem.city || '',
      doctor: selectedItem.doctor || '',
      region: selectedItem.region || '',
      address: selectedItem.address || '',
      phone: selectedItem.phone || '',
      clinic: selectedItem.clinic || '',
      promocode: selectedItem.promocode || '',
      bonusMethod: selectedItem.bonusMethod || '',
      deliveryPlace: selectedItem.deliveryPlace || '',
      loyalty: selectedItem.loyalty || '',
      specialistType: selectedItem.specialistType || '',
      doctorCategory: selectedItem.doctorCategory || '',
      seriaAcne: selectedItem.seriaAcne || 0,
      seriaRozacea: selectedItem.seriaRozacea || 0,
      seriaSeboreya: selectedItem.seriaSeboreya || 0,
      seriaHyperpigmentation: selectedItem.seriaHyperpigmentation || 0
    };
    setInputData(updatedData);
    setDisabled(selectedItem.activated);
  }, [selectedItem]);

  return (
    <Box style={{ marginTop: 160, padding: '0 10px' }}>
      <Paper elevation={3} style={{ padding: '20px 20px' }}>
        {userData.role === 'Manager1' ? <Typography><strong>Дані спеціаліста:</strong> {selectedItem.promocode.startsWith('t_') ? '-' : selectedItem.promocode} {!selectedItem.activated && <Button
          variant="contained"
          size="small"
          onClick={toggleDisabled}
          disabled={!isSaved}
        >Активувати
        </Button>
        } {<Button variant="contained" size="small" color="primary" disabled={isSaved} onClick={handleSave}>
          Зберегти
        </Button>}
        </Typography> : <Typography><strong>Дані промокоду:</strong> {selectedItem.promocode.startsWith('t_') ? '-' : selectedItem.promocode}</Typography>}
        <Grid container item style={{ marginBottom: 20 }} spacing={2}>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="ПІБ"
              field='doctor'
              type='text'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : disabled}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalSelect
              item={inputData}
              label="Область*"
              field='region'
              type='text'
              handleSelectChange={handleSelectChange}
              disabled={userData.role !== 'Manager1' ? true : disabled}
              style={{ marginTop: 3 }}
              menuItems={regionNameList}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Місто"
              field='city'
              type='text'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : disabled}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Адреса"
              field='address'
              type='text'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Номер телефону"
              field='phone'
              type='text'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : disabled}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="ЛПУ"
              field='clinic'
              type='text'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Спосіб бонусації"
              field='bonusMethod'
              type='text'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : disabled}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Місце доставки"
              field='deliveryPlace'
              type='text'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : false}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm>
            <UniversalSelect
              item={inputData}
              label="Категорія"
              field='doctorCategory'
              type='text'
              handleSelectChange={handleSelectChange}
              disabled={userData.role !== 'Manager1' ? true : false}
              style={{ marginTop: 3 }}
              menuItems={doctorCategory}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalSelect
              item={inputData}
              label="Спеціальність"
              field='specialistType'
              type='text'
              handleSelectChange={handleSelectChange}
              disabled={userData.role !== 'Manager1' ? true : false}
              style={{ marginTop: 3 }}
              menuItems={specialistType}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalSelect
              item={inputData}
              label="Лояльність"
              field='loyalty'
              type='text'
              handleSelectChange={handleSelectChange}
              disabled={userData.role !== 'Manager1' ? true : false}
              style={{ marginTop: 3 }}
              menuItems={doctorCategory}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Акне"
              field='seriaAcne'
              type='number'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Розацеа"
              field='seriaRozacea'
              type='number'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Себорея"
              field='seriaSeboreya'
              type='number'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm>
            <UniversalInput
              item={inputData}
              label="Гіперпігментація"
              field='seriaHyperpigmentation'
              type='number'
              handleInputChange={handleInputChange}
              disabled={userData.role !== 'Manager1' ? true : false}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} style={{ margin: '30px 0', padding: '20px 20px' }}>
        <Comment
          userData={userData}
          promocode={selectedItem.promocode}
          promocodeComments={promocodeComments}
        />
      </Paper>
      <Paper elevation={3} style={{ width: 'fit-content', margin: '30px 0', padding: '20px 20px' }}>
        <TableContainer>
          <Typography><strong>Продажі:</strong></Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Поточний місяць</TableCell>
                {previousMonths.map((yearMonth, index) => {
                  const month = yearMonth.slice(-2);
                  const monthName = (monthNameList.find(m => m.month === month)?.name || month).toLowerCase();
                  return (
                    <TableCell key={index}>{monthName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Продажі уп.</TableCell>
                <TableCell>{saleQtySum}</TableCell>
                {selectedData.previousData.map((item, index) => (
                  <TableCell key={index}>{item[`${previousMonths[index]}_productQuantitySum`]}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Продажі сума</TableCell>
                <TableCell>{salePriceSum}</TableCell>
                {selectedData.previousData.map((item, index) => (
                  <TableCell key={index}>{item[`${previousMonths[index]}_productTotalPriceSum`]}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Бонус по промокоду</TableCell>
                <TableCell>{selectedBonusItem?.bonusSum || 0}</TableCell>
                {selectedBonusItem?.previousData.map((item, index) => (
                  <TableCell key={index}>{item[`${previousMonths[index]}_BonusSum`]}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Оплачено бонус</TableCell>
                <TableCell>{selectedBonusItem && selectedBonusItem.isPayed ? selectedBonusItem.toPaySum : 0}</TableCell>
                {selectedBonusItem?.previousData.map((item, index) => {
                  return (
                    <TableCell key={index}>{item[`${previousMonths[index]}_isPayed`] ? item[`${previousMonths[index]}_toPaySum`] : 0}</TableCell>
                  )
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper elevation={3} style={{ width: 'fit-content', margin: '30px 0', padding: '20px 20px' }}>
        <Grid container item style={{ marginBottom: 50 }}>
          <Box display="flex" flexDirection="column">
            <Typography style={{ marginBottom: 20 }}><strong>Призначення продуктів:</strong></Typography>
            {productsByQuantity && Object.keys(productsByQuantity).map((key, index) => (
              <Grid container item xs={12} spacing={3}>
                <Grid item xs>
                  <TextField
                    id="standard-basic"
                    value={key}
                    style={{ width: 220 }}
                  />
                </Grid>
                <Grid item xs style={{ width: 100 }}>
                  <TextField
                    label={''}
                    id="standard-basic"
                    value={productsByQuantity[key]}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ItemCard;