// Regionitem.js

import React, { useEffect, useState, useRef } from 'react';
import { TableCell, Checkbox, TextField, TableRow } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useDispatch } from 'react-redux';
import { updateBonusItemAction } from '../../data/actions/bonusSystemActions';
import { Link, useHistory, useLocation } from 'react-router-dom';

const RegionitemItem = React.memo(({ item, userData, showPreviousMonths }) => {

  const [inputData, setInputData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const bonusRecommendedRef = useRef({});
  const appointmentsRef = useRef({});

  const handleInputChange = (itemId, field, event) => {
    const value = event.target.value;
    if (value === '' || !isNaN(value)) {
      setInputData(prevData => ({
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          [field]: value
        }
      }));
    }
  };

  const handleSave = (itemId, field, newData) => {
    const editedItem = {
      ...newData[itemId],
      [field]: newData[itemId][field] !== undefined ? newData[itemId][field] : 0
    };
    const promocode = item.promocode;
    dispatch(updateBonusItemAction(itemId, editedItem, userData, promocode), () => {
      // Clear data after saving, if necessary
      setInputData(prevData => {
        const { [field]: removed, ...rest } = prevData[itemId];
        return {
          ...prevData,
          [itemId]: rest
        };
      });
    });

    // Remove focus
    if (field === 'bonusRecommended') {
      bonusRecommendedRef.current[itemId].blur();
    } else if (field === 'appointments') {
      appointmentsRef.current[itemId].blur();
    }
  };

  const handleKeyPress = (itemId, field, e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave(itemId, field, inputData);
    }
  };

  const handleCheckboxChange = (itemId, field, event) => {
    const isChecked = event.target.checked;

    // Calculate the total sum of unpaid recommended bonuses
    let totalUnpaidBonus = 0;
    item.previousData.forEach(prevData => {
      const prevBonusRecommendedKey = Object.keys(prevData).find(key => key.includes('_BonusRecommended'));
      const prevBonusRecommendedValue = prevData[prevBonusRecommendedKey];
      const prevIsPayedKey = Object.keys(prevData).find(key => key.includes('_isPayed'));
      const prevIsPayedValue = prevData[prevIsPayedKey];

      if (!prevIsPayedValue) {
        totalUnpaidBonus += Number(prevBonusRecommendedValue);
      }
    });

    const toPaySum = totalUnpaidBonus + Number(item?.bonusRecommended);

    setInputData(prevData => {
      const newData = {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          [field]: isChecked,
          toPaySum: toPaySum
        }
      };
      handleSave(itemId, field, newData);
      return newData;
    });

    // Update toPay for previous months
    item.previousData.forEach(prevData => {
      const prevBonusId = Object.keys(prevData).find(key => key.includes('_id'));
      const itemId = prevData[prevBonusId]
      const newData = {
        [itemId]: {
          toPay: isChecked
        }
      };

      handleSave(itemId, 'toPay', newData);
    });
  };

  // console.log("prevData", item.previousData);

  const isDisabledColor = 'rgb(119, 119, 119)'

  const itemId = item.id;

  const isCheckboxChecked = item.toPay || false;
  const isPayed = item.isPayed || false;

  const calculateTotal = (item) => {
    return item.previousData.reduce((total, prevData) => {
      const prevBonusRecommendedKey = Object.keys(prevData).find(key => key.includes('_BonusRecommended'));
      const prevBonusRecommendedValue = prevData[prevBonusRecommendedKey];
      const prevIsPayedKey = Object.keys(prevData).find(key => key.includes('_isPayed'));
      const prevIsPayedValue = prevData[prevIsPayedKey];

      if (!prevIsPayedValue) {
        total += Number(prevBonusRecommendedValue);
      }
      return total;
    }, Number(item.bonusRecommended));
  }

  const totalForPay = calculateTotal(item);
  if (item.toPay && item.toPaySum !== null && totalForPay !== item.toPaySum) {
    console.log('error', item.promocode, totalForPay, item.toPaySum);
  }
  // console.log("🚀 ~ RegionitemItem ~ totalForPay:", item.promocode, totalForPay !== item.toPaySum, totalForPay, item.toPaySum)
  // item.toPaySum !== null && totalForPay !== item.toPaySum ? console.log('error', item.promocode, totalForPay, item.toPaySum) : '0';
  return (
    <TableRow
      key={itemId}
      style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}
    >
      <TableCell
        style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}
        title={totalForPay < 500 ? "Не достатня сума для оплати" : ""}
      >
        <Checkbox
          checked={isCheckboxChecked}
          disabled={isCheckboxChecked || userData.role !== 'Manager1' || totalForPay < 500}
          onChange={(e) => handleCheckboxChange(itemId, 'toPay', e)}
        />
      </TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.region}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.city}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>
        {item.promocode.startsWith('t_') ? '-' : item.promocode}
      </TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>
        <Link to={{
          pathname: userData.role !== 'Manager1' ? "/admin/bonusSystemItem" : "/bonusSystemItem",
          state: {
            doctor: item.doctor, promocode: item.promocode, year: item.bonusYear, month: item.bonusMonth,
            from: location.pathname
          }
        }}
          title='Перейти в карточку спеціаліста'
        >
          {item.doctor}
        </Link>
      </TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.doctorCategory}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>
        <DescriptionIcon />
      </TableCell>
      <TableCell
        style={{
          color: isCheckboxChecked && isPayed ? '#00bb17' : isCheckboxChecked ? isDisabledColor : 'black', fontWeight: '700'
        }}
        title={isCheckboxChecked && isPayed ? 'Оплачено' : 'Не оплачено'}
      >
        {!item.toPaySum ? totalForPay : item.toPaySum}
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          required
          placeholder="Введіть дані....."
          value={inputData[itemId]?.bonusRecommended ?? item.bonusRecommended}
          onChange={(e) => handleInputChange(itemId, 'bonusRecommended', e)}
          onKeyPress={(e) => handleKeyPress(itemId, 'bonusRecommended', e)}
          onBlur={(e) => handleSave(itemId, 'bonusRecommended', inputData)}
          inputRef={ref => bonusRecommendedRef.current[itemId] = ref}
          disabled={isCheckboxChecked || userData.role !== 'Manager1'}
          style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}
        />
      </TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.ordersSum}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.bonusSum}</TableCell>
      <TableCell>
        <TextField
          type="number"
          required
          placeholder="Введіть дані..."
          value={inputData[itemId]?.appointments ?? item.appointments}
          onChange={(e) => handleInputChange(itemId, 'appointments', e)}
          onKeyPress={(e) => handleKeyPress(itemId, 'appointments', e)}
          onBlur={(e) => handleSave(itemId, 'appointments', inputData)}
          inputRef={ref => appointmentsRef.current[itemId] = ref}
          disabled={isCheckboxChecked || userData.role !== 'Manager1'}
          style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}
        />
      </TableCell>
      {item.previousData.map((item, index) => (
        <React.Fragment key={index}>
          {(index === 0 || (index > 0 && showPreviousMonths)) && Object.entries(item).map(([key, value]) => {
            const valuesToCheck = ['isPayed', 'id', 'promocode', 'toPay'];
            if (valuesToCheck.some(value => key.includes(value))) return null;

            const [year, month, property] = key.split('_');
            const isPayed = item[`${year}_${month}_isPayed`];
            const isBonusRecommended = property === 'BonusRecommended';
            return (
              <TableCell
                key={key}
                title={property === 'BonusRecommended' ? (isPayed === false || isPayed === null ? 'Бонус не оплачений' : 'Оплачено') : ''}
                style={{
                  color: (isPayed === false || isPayed === null) && isBonusRecommended ? 'orange' : isCheckboxChecked ? isDisabledColor : 'black'
                }}
              >
                {value}
              </TableCell>
            );
          })}
        </React.Fragment>
      ))}
    </TableRow>
  )
});

export default RegionitemItem;

// Последовательность действий по оплате бонусов:
// Меняем в бд статус isPayed на 0
// Меняем в бд статус toPay на 0
// каждый менеджер проставляет заново чекбоксы
// Ставлю чекбоксы на странице оплаты бонусов